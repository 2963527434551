@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {

     /* remove variamts responsive if using tailwindV3 */
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari, and Opera */
      .no-scrollbar::-webkit-scrollbar {
        display: none;
      }
  
  
      /* Hide scrollbar for IE, Edge, and Firefox */
      .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  } 
@layer components {

    .cardBtn{
        @apply bg-gradient-to-b from-[#c6f6d5]  to-[#34dd5a] transform active:scale-95 text-[#000F99] rounded-full 

    }

    .lockedCardButton{
        @apply flex-nowrap flex-shrink ml-2 px-3  shadow-md focus:outline-none cursor-pointer bg-gradient-to-b from-[#c6f6d5]  to-[#34dd5a] transform active:scale-95 text-[#000F99] rounded-full text-lg

    }
    .lockedCardButton2{
        @apply flex-nowrap flex-shrink ml-2 px-3  shadow-md focus:outline-none cursor-pointer bg-gradient-to-b from-[#7ff3a4]  to-[#3cfd66] transform active:scale-95 text-[#000F99] rounded-full text-lg z-0

    }


}

@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&family=Fredoka:wght@300;400;500;600;700&family=Mina:wght@400;700&family=Miriam+Libre:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
